import { CubeBuilder, NOOP_VOID, Place } from 'wdc-cube'
import { Places } from './Constants'
import {
    ChannelConfigurationFormPresenter,
    ChannelConfigurationListingPresenter,
    ChannelConfigurationMenuEditorFormPresenter,
    ChannelConfigurationWorkingHoursEditorFormPresenter
} from './feature/channel'
import { ChannelConfigurationCompanyConfigurationFormPresenter } from './feature/channel/companyConfiguration/ChannelConfigurationCompanyConfiguration.presenter'
import { ChannelConfigurationSelfRescueFormPresenter } from './feature/channel/selfRescue/ChannelConfigurationSelfRescueForm.presenter'
import { ChannelConfigurationMenuSegmentationEditorFormPresenter } from './feature/channel/menuSegmentation/ChannelConfigurationMenuSegmentationEditorForm.presenter'
let buildOnce = () => {
    CubeBuilder.build({
        'config/channel': {
            presenter: Place.creator(ChannelConfigurationListingPresenter, Places, 'channelConfigurationListing'),

            form: {
                presenter: Place.creator(ChannelConfigurationFormPresenter, Places, 'channelConfigurationForm'),
                selfRescue: {
                    presenter: Place.creator(
                        ChannelConfigurationSelfRescueFormPresenter,
                        Places,
                        'channelConfigurationSelfRescueForm'
                    )
                },
                companyConfiguration: {
                    presenter: Place.creator(
                        ChannelConfigurationCompanyConfigurationFormPresenter,
                        Places,
                        'channelConfigurationCompanyConfigurationForm'
                    )
                },
                menu: {
                    presenter: Place.creator(
                        ChannelConfigurationMenuEditorFormPresenter,
                        Places,
                        'channelConfigurationMenuEditorForm'
                    )
                },
                menuSegmentation: {
                    presenter: Place.creator(
                        ChannelConfigurationMenuSegmentationEditorFormPresenter,
                        Places,
                        'channelConfigurationMenuSegmentationEditorForm'
                    )
                },
                times: {
                    presenter: Place.creator(
                        ChannelConfigurationWorkingHoursEditorFormPresenter,
                        Places,
                        'channelConfigurationTimesEditorForm'
                    )
                }
            }
        }
    })

    buildOnce = NOOP_VOID
}

export function buildCube() {
    buildOnce()
    return Places
}
