import { Collapse, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SlideProps } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import SimCardIcon from '@mui/icons-material/SimCard'
import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { MenuScope } from '../Main.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeMenuStyles } from './Main.styles'

// @Inject
const texts = TextsProvider.get()

export type MenuViewProps = IViewProps & {
    scope: MenuScope
}

const slideProps: Partial<SlideProps> = {
    unmountOnExit: true
}

export function MenuView({ className, scope }: MenuViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeMenuStyles()

    return (
        <>
            <Drawer
                className={clsx(styles.drawer, className)}
                variant="persistent"
                open={scope.opened}
                onClose={scope.onClose}
                SlideProps={slideProps}
            >
                <div className={styles.drawerContainer}>
                    <List component="nav" aria-labelledby="nested-list-subheader" className={styles.menuList}>
                        <ListItem>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary={texts.MENU_SETTINGS_OPTION_TITLE} />
                        </ListItem>
                        <Collapse in={true} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton className={styles.subMenuItem} onClick={scope.onConfigureChannel}>
                                    <ListItemIcon>
                                        <SimCardIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={texts.MENU_CHANNEL_OPTION} />
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </List>
                </div>
            </Drawer>
        </>
    )
}
